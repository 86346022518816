import Vue from 'vue'
import VueRouter from 'vue-router'
// import IndexView from '../views/IndexView.vue'
import i18n from '../locale/index'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: '首页',
    meta: {
      title: '十如研究院'
    },
    component: () => import('../views/IndexView.vue'),
    children: [{
        path: '/',
        redirect: '/index'
      },
      // 首页二级
      {
        path: 'index',
        name: 'index',
        meta: {
          title: '十如研究院'
        },
        component: () =>
          import("../pages/Index/indexView.vue"),
      },
      {
        path: '/about',
        name: '关于我们',
        component: () => import('../pages/about/AboutView.vue'),
        meta: {
          title: '关于我们'
        },
      },
      {
        path: '/contact',
        name: 'contact',
        component: () => import('../pages/contact/ContactView.vue'),
        meta: {
          title: '联系我们'
        },
      },
      {
        path: '/news',
        name: 'news',
        component: () => import('../pages/news/NewsView.vue'),
        meta: {
          title: '新闻资讯'
        },
      },
      {
        path: '/education',
        name: 'education',
        component: () => import('../pages/Education&Training/education.vue'),
        meta: {
          title: '教育与培训'
        },
      },
      // Education&Training
      {
        path: '/events',
        name: 'events',
        component: () => import('../pages/Events&Networking/eventsView.vue'),
        meta: {
          title: '会议、活动和网络'
        },
      },
      {
        path: '/research',
        name: 'research',
        component: () => import('../pages/Research&Consultation/researchView.vue'),
        meta: {
          title: '研究与咨询'
        },
      },
      // 研究与咨询
      // 教育与培训
      {
        path: '/Privacy',
        name: 'Privacy',
        component: () => import('../pages/PrivacyPolicy.vue'),
        meta: {
          title: '隐私政策'
        },
      },
      {
        path: '/details/:id',
        name: 'newsDetails',
        component: () => import('../pages/newsDetails/newsDetails.vue'),
        meta: {
          title: '新闻资讯详情'
        },
      },
      {
        path: '/MembersDetails/:id',
        name: 'MembersDetails',
        component: () => import('../pages/mambersDetails.vue'),
        meta: {
          title: '成员详情'
        },
      },
    
    ]
  },

]


const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, form, next) => {
  if (to.meta.title) {


    if (i18n.locale == 'zh') {
      document.title = to.meta.title
    } else if (i18n.locale == 'en') {
      document.title = "integral institute"

    }
  } else {
    document.title = '十如研究院' //默认的title
  }
  next()
})

export default router